<template>
  <div class="content-provider-detail">
    <div class="global-content-detail" :style="{ height: screenHeight + 'px' }">
      <div v-if="sTabPosition == 0" class="content-identification-information">
        <p class="text-identification-information">
          <v-skeleton-loader width="30%" type="text"></v-skeleton-loader>
        </p>
        <v-row>
          <v-col cols="12" sm="3" md="3" lg="3" xl="3">
            <div v-if="!bModify">
              <p class="text-title">
                <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
              </p>
              <p class="text-information">
                <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
              </p>
            </div>
          </v-col>
          <v-col cols="12" sm="3" md="3" lg="3" xl="3">
            <div v-if="!bModify">
              <p class="text-title">
                <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
              </p>
              <p class="text-information">
                <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
              </p>
            </div>
          </v-col>
          <v-col cols="12" sm="6" md="6" lg="6" xl="6"> <skeleton-phone-component-global/> </v-col>

          <v-col cols="12" sm="3" md="3" lg="3" xl="3">
            <div v-if="!bModify">
              <p class="text-title">
                <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
              </p>
              <p class="text-information">
                <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
              </p>
            </div>
          </v-col>
          <v-col cols="12" sm="3" md="3" lg="3" xl="3">
            <div v-if="!bModify">
              <p class="text-title">
                <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
              </p>
              <p class="text-information">
                <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
              </p>
            </div>
          </v-col>
          <v-col cols="12" sm="3" md="3" lg="3" xl="3">
            <div v-if="!bModify">
              <p class="text-title">
                <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
              </p>
              <p class="text-information">
                <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
              </p>
            </div>
          </v-col>
          <v-col cols="12" sm="3" md="3" lg="3" xl="3">
            <div v-if="!bModify">
              <p class="text-title">
                <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
              </p>
              <p class="text-information">
                <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
              </p>
            </div>
          </v-col>

          <v-col cols="12" sm="6" md="6" lg="6" xl="6">
            <div v-if="!bModify">
              <p class="text-title">
                <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
              </p>
              <p class="text-information">
                <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
              </p>
            </div>
          </v-col>
          <v-col cols="12" sm="6" md="6" lg="6" xl="6">
            <div v-if="!bModify">
              <p class="text-title">
                <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
              </p>
              <p class="text-information">
                <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
              </p>
            </div>
          </v-col>
        </v-row>
      </div>
      <div v-if="sTabPosition == 0" class="content-identification-information">
        <p class="text-identification-information">
          <v-skeleton-loader width="30%" type="text"></v-skeleton-loader>
        </p>
        <v-row>
          <v-col cols="12" sm="3" md="3" lg="3" xl="3">
            <div v-if="!bModify">
              <p class="text-title">
                <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
              </p>
              <p class="text-information">
                <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
              </p>
            </div>
          </v-col>
          <v-col cols="12" sm="3" md="3" lg="3" xl="3">
            <div v-if="!bModify">
              <p class="text-title">
                <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
              </p>
              <p class="text-information">
                <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
              </p>
            </div>
          </v-col>
          <v-col cols="12" sm="6" md="6" lg="6" xl="6"> <skeleton-phone-component-global/> </v-col>
        </v-row>
      </div>
      <div v-if="sTabPosition == 0" class="content-identification-information">
        <p class="text-identification-information">
          <v-skeleton-loader width="30%" type="text"></v-skeleton-loader>
        </p>
        <v-row>
          <v-col cols="12" sm="3" md="3" lg="3" xl="3">
            <div v-if="!bModify">
              <p class="text-title">
                <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
              </p>
              <p class="text-information">
                <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
              </p>
            </div>
          </v-col>
          <v-col cols="12" sm="3" md="3" lg="3" xl="3">
            <div v-if="!bModify">
              <p class="text-title">
                <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
              </p>
              <p class="text-information">
                <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
              </p>
            </div>
          </v-col>
          <v-col cols="12" sm="3" md="3" lg="3" xl="3">
            <div v-if="!bModify">
              <p class="text-title">
                <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
              </p>
              <p class="text-information">
                <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
              </p>
            </div>
          </v-col>

          <v-col cols="12" sm="3" md="3" lg="3" xl="3"> </v-col>

          <v-col cols="12" sm="3" md="3" lg="3" xl="3">
            <div v-if="!bModify">
              <p class="text-title">
                <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
              </p>
              <p class="text-information">
                <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
              </p>
            </div>
          </v-col>
          <v-col cols="12" sm="3" md="3" lg="3" xl="3">
            <div v-if="!bModify">
              <p class="text-title">
                <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
              </p>
              <p class="text-information">
                <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
              </p>
            </div>
          </v-col>
          <v-col cols="12" sm="3" md="3" lg="3" xl="3">
            <div v-if="!bModify">
              <p class="text-title">
                <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
              </p>
              <p class="text-information">
                <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
              </p>
            </div>
          </v-col>
          <v-col cols="12" sm="3" md="3" lg="3" xl="3">
            <div v-if="!bModify">
              <p class="text-title">
                <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
              </p>
              <p class="text-information">
                <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
              </p>
            </div>
          </v-col>

          <v-col cols="12" sm="6" md="6" lg="6" xl="6">
            <div v-if="!bModify">
              <p class="text-title">
                <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
              </p>
              <p class="text-information">
                <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
              </p>
            </div>
          </v-col>
          <v-col cols="12" sm="6" md="6" lg="6" xl="6">
            <div v-if="!bModify">
              <p class="text-title">
                <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
              </p>
              <p class="text-information">
                <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
              </p>
            </div>
          </v-col>
        </v-row>
      </div>

      <div v-if="sTabPosition == 1" class="content-identification-information">
        <p class="text-identification-information">
          <v-skeleton-loader width="30%" type="text"></v-skeleton-loader>
        </p>
        <v-row>
          <v-col cols="12" sm="3" md="3" lg="3" xl="3">
            <div v-if="!bModify">
              <p class="text-title">
                <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
              </p>
              <p class="text-information">
                <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
              </p>
            </div>
          </v-col>
          <v-col cols="12" sm="3" md="3" lg="3" xl="3">
            <div v-if="!bModify">
              <p class="text-title">
                <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
              </p>
              <p class="text-information">
                <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
              </p>
            </div>
          </v-col>
          <v-col cols="12" sm="6" md="6" lg="6" xl="6"> <skeleton-phone-component-global/> </v-col>

          <v-col cols="12" sm="3" md="3" lg="3" xl="3">
            <div v-if="!bModify">
              <p class="text-title">
                <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
              </p>
              <p class="text-information">
                <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
              </p>
            </div>
          </v-col>
          <v-col cols="12" sm="3" md="3" lg="3" xl="3">
            <div v-if="!bModify">
              <p class="text-title">
                <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
              </p>
              <p class="text-information">
                <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
              </p>
            </div>
          </v-col>
          <v-col cols="12" sm="3" md="3" lg="3" xl="3">
            <div v-if="!bModify">
              <p class="text-title">
                <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
              </p>
              <p class="text-information">
                <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
              </p>
            </div>
          </v-col>
          <v-col cols="12" sm="3" md="3" lg="3" xl="3">
            <div v-if="!bModify">
              <p class="text-title">
                <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
              </p>
              <p class="text-information">
                <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
              </p>
            </div>
          </v-col>

          <v-col cols="12" sm="6" md="6" lg="6" xl="6">
            <div v-if="!bModify">
              <p class="text-title">
                <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
              </p>
              <p class="text-information">
                <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
              </p>
            </div>
          </v-col>
          <v-col cols="12" sm="6" md="6" lg="6" xl="6">
            <div v-if="!bModify">
              <p class="text-title">
                <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
              </p>
              <p class="text-information">
                <v-skeleton-loader width="auto" type="text"></v-skeleton-loader>
              </p>
            </div>
          </v-col>
        </v-row>
      </div>

      <v-divider class="divider-global"></v-divider>
      <div class="content-btns-accions">
        <div class="content-btn-second">
          <v-skeleton-loader width="auto" type="button"></v-skeleton-loader>
        </div>

        <v-spacer></v-spacer>
        <div class="content-btn-primary">
          <v-skeleton-loader width="auto" type="button"></v-skeleton-loader>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "ProviderDetail",
  data() {
    return {
      screenHeight: 0,
      heightMax: 0,
      bModify: false,

    };
  },
  beforeMount() {
  },
  mounted() {
    this.matchHeight();
    this.handleResize();
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    // this.$store.commit("setsNameDetail", "");

    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    matchHeight() {
      if (this.$refs.searchProvider !== undefined) {
        this.heightMax = this.$refs.searchProvider.clientHeight;
        this.heightMax = this.heightMax + 123;
      }
    },
    handleResize: function () {
      this.matchHeight();
      if (window.innerWidth > 600) {
        this.screenHeight = window.innerHeight - this.heightMax;
      } else {
        this.screenHeight = window.innerHeight - this.heightMax;
      }
    },
  },
  computed: {
    sTabPosition() {
      return this.$store.state.sTabPosition;
    },
  },
  watch: {

  },
};
</script>

<style scoped>
.content-identification-information {
  margin-bottom: 25px;
  padding: 0px 0px 0px 12px;
}
.img-ine-card {
  width: 100% !important;
}
.text-identification-information {
  color: var(--primary-color-color-title-information);
  font-family: "pop-Bold";
  font-size: 16px;
  letter-spacing: 3px;
}
.text-tax-information {
  color: var(--primary-color-color-title-information);
  font-family: "pop-Bold";
  font-size: 16px;
  letter-spacing: 3px;
}
.content-tax-information {
  margin-bottom: 25px;
  padding: 0px 0px 0px 25px;
}

.content-carousel-1 {
  position: relative;
  /* height: 170px; */
  width: 100%;
  /* border: var(--primary-color-menu) dashed; */
  border-radius: 20px;
  justify-content: center;
  display: flex;
}
.content-carousel-2 {
  position: relative;
  /* height: 170px; */
  width: 100%;
  /* border: var(--primary-color-menu) dashed; */
  border-radius: 20px;
  justify-content: center;
  display: flex;
}

.content-icon-carousel {
  z-index: 9;
  position: absolute;
  display: flex;
  bottom: 10px;
  /* background-color: var(--primary-color-text-yellow); */
}
.content-icon-1 {
  cursor: pointer;
  border-radius: 100%;
  height: 10px;
  width: 10px;
  background-color: white;
}

.content-icon-2 {
  cursor: pointer;
  border-radius: 100%;
  height: 10px;
  width: 10px;
  margin-left: 10px;
  background-color: white;
}

.text-img-add {
  color: var(--primary-color-text) !important;
  font-family: "pop-Semibold";
  font-size: 9px;
}

.content-image {
  width: 100%;
  height: 180px !important;
  position: relative;
  border: 1px solid #70707080;
  border-radius: 20px;
}

.content-image img {
  width: 100%;
  height: auto;
}

@supports (object-fit: cover) {
  .content-image img {
    height: 100%;
    object-fit: cover;
    object-position: center center;
    border-radius: 20px;
  }
}
.button-delete-item {
  position: absolute;
  right: 5px;
  top: 5px;
  min-width: 40px !important;
  height: 40px !important;
  background-color: var(--primary-color-btn-neutral-red) !important;
  padding: 0px !important;
  border-radius: 10px;
}
.button-add-img {
  width: 100% !important;
  height: 180px !important;
  background: transparent 0% 0% no-repeat padding-box !important;
  border: 2px dashed var(--primary-color-divider);
  border-radius: 20px;
  opacity: 1;
}

.content-arrows-carousel {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.text-title {
  margin-bottom: 2px;
  color: var(--primary-color-text-title-detail);
  font-family: "pop-Regular";
  opacity: 1;
  letter-spacing: 0px;
}
.text-information {
  margin-bottom: 5px;
  color: var(--primary-color-text);
  font-family: "pop-Regular";
}
.content-btns-accions {
  display: flex;
  margin-top: 25px;
  width: 100% !important;
}
.content-btn-primary {
  width: 200px;
}
.content-btn-second {
  width: 200px;
  margin-right: 15px;
}

.carousel-card {
  height: 30vh !important;
  width: 50vw !important;
}
.content-arrows-carousel {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.text-identification {
  margin-bottom: 0px;
  color: var(--primary-color-text);
}

.btns-actions-carousel {
  width: 100%;
  margin-top: 10px;
  display: flex;
  justify-content: end;
}
.btn-view {
  background-color: var(--primary-color-background-btn-view-img) !important;
  border-radius: 20%;
  width: 40px !important;
  min-width: 40px !important;
  max-width: 40px !important;
  margin-right: 15px;
}
.btn-delete {
  background-color: var(--primary-color-btn-table-delete) !important;
  border-radius: 20%;
  width: 40px !important;
  min-width: 40px !important;
  max-width: 40px !important;
  margin-right: 15px;
}

/**************************MODO RESPONSIVO ********************/
/* XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }
  .content-btns-accions {
    display: block;
    width: 100% !important;
  }

  .content-btn-second {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 15px;
  }

  .content-btn-primary {
    width: 100%;
  }
  .carousel-card {
    height: 30vh !important;
    width: 100vw !important;
  }
}

/* SM */
@media (min-width: 600px) and (max-width: 959px) {
  .demoColorResponsive {
    background-color: rgb(136, 226, 151);
  }
  .carousel-card {
    height: 30vh !important;
    width: 100vw !important;
  }
}

/* MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }
}

/* LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}

/* XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}
</style>
